<template>
    <div class="h-50">
        <van-nav-bar :title="$t('pageTitle.task_category')" :border="false">
            <template #left>
                <div class="page-title-btn" @click="$emit('close')">
                    <!-- <div class="page-title-btn" @click="$router.go(-1)"> -->
                    <img src="@/assets/images/arrow_left.svg" alt="" srcset="" />
                </div>
            </template>
        </van-nav-bar>
        <div class="delegete-wrap" v-if="!isLoading">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text=""
                :loading-text="$t('btn.loading')"
                @load="onLoad">
                <div
                    class="delegete-item"
                    v-for="(item, key) in categoryList"
                    :key="key"
                    @click="setCategory(item)">
                    <p class="delegete-item__name">{{ item.title }}</p>
                </div>
            </van-list>
        </div>
        <!-- <van-button type="primary" class="one" @click="$emit('close')">
            {{ $t('btn.choose') }}
        </van-button> -->
        <van-loading color="#1989fa" size="64" class="txt-c" v-else />
    </div>
</template>

<script>
import ApiCategory from '@/api/category';
import ApiTasks from '@/api/tasks';

export default {
    props: {
        isCategory: {
            type: Boolean,
            default: false,
        },
        task_id: {
            type: Number,
            default: 0,
        },
    },
    // watch: {
    //     isCategory() {
    //         this.isCopyCategory = this.isCategory;
    //         if (this.isCategory) {
    //         }
    //     },
    // },
    data() {
        return {
            isLoading: true,
            isCopyCategory: false,
            categoryList: [],
            loading: false,
            finished: false,
            params: {
                limit: 10,
            },
        };
    },
    methods: {
        onLoad() {
            this.params.limit += 50;

            ApiCategory.getCategoryList(this.params).then((responce) => {
                this.categoryList = responce.data.slice(1);
                this.isLoading = false;
                this.tasksList = responce.data;
                this.loading = false;
                this.finished = true;
                // if (responce.data.length == this.choosenCategory.tasks) {
                // }
            });
        },
        setCategory(item) {
            let params = {
                id: this.task_id,
                id_category: item.id,
            };
            ApiTasks.putTask(params).then(() => {
                this.$notify(this.$t('task.move_task', { name: item.title }), {
                    type: 'success',
                });
                // this.$router.push('/');
                this.$emit('close');
            });
        },
    },
    mounted() {
        this.isLoading = true;
        ApiCategory.getCategoryList().then((responce) => {
            this.categoryList = responce.data.slice(1);
            this.isLoading = false;
        });
    },
};
</script>