<template>
    <van-dialog
        v-model="isCopyCreate"
        :title="$t('task.create.title')"
        :showConfirmButton="false"
        :closeOnClickOverlay="true"
        ref="createForm"
        @close="resetForm">
        <input
            type="text"
            :class="{ isError }"
            v-model="form.text"
            class="category__input"
            :placeholder="$t('task.create.title')" />
        <small v-if="isError">{{ $t('error.required_task') }}</small>
        <van-button type="primary" class="one" @click="save()" :loading="isSaving">
            {{ $t('category.done') }}
        </van-button>
    </van-dialog>
</template>

<script>
import ApiTask from '@/api/tasks';
export default {
    props: {
        isCreate: {
            default: false,
            type: Boolean,
        },
        task: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isError: false,
            isSaving: false,
            isCopyCreate: false,
            form: {},
        };
    },
    watch: {
        isCreate() {
            this.isCopyCreate = this.isCreate;
            this.form = JSON.parse(JSON.stringify(this.task));
        },
    },

    methods: {
        save() {
            if (this.form.text.length <= 0) {
                this.isError = true;
                return;
            }

            this.isError = false;
            this.isSaving = true;
            let data = {
                id: this.form.id,
                text: this.form.text,
            };
            ApiTask.putTask(data).then((responce) => {
                this.isSaving = false;
                if (responce.err != 0) {
                    this.$notify(responce.err_msg[0], {
                        type: 'danger',
                    });
                } else {
                    this.resetForm();
                }
            });
        },
        resetForm() {
            this.$emit('close');
            this.$emit('updateTask', this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
.category__input {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #111;
    padding: 6px 12px;
    border: 1px solid #9e9e9e;
    border-radius: 6px;
    margin-bottom: 24px;
    width: 100%;
    outline: none;

    &.isError {
        border-color: red;
        margin-bottom: 0;
        & + small {
            margin-bottom: 24px;
            color: red;
            display: block;
        }
    }
    &::placeholder {
        color: #9e9e9e;
    }
}
</style>
