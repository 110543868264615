<template>
    <div class="task-controll">
        <div class="btn-row">
            <van-button type="primary" @click="doneTask()">
                {{ $t('btn.complite') }}
            </van-button>
            <van-button plain type="primary" @click="$emit('delegete')">
                {{ $t('btn.delegete') }}
            </van-button>
        </div>
        <div class="btn-row">
            <van-button plain type="primary" @click="$emit('category')">
                {{ task.id_category == 0 ? $t('btn.change_category') : $t('btn.choose_category') }}
            </van-button>
            <van-button plain type="primary" @click="getOriginalMessage">
                {{ $t('btn.message') }}
            </van-button>
        </div>
        <div class="btn-row">
            <van-button plain type="primary" @click="isCreate = true">
                {{ $t('btn.edit') }}
            </van-button>
            <van-button plain type="primary" @click="deleteTask()">
                <img src="@/assets/images/trash.svg" class="icon" />
                {{ $t('btn.delete') }}
            </van-button>
        </div>

        <!-- <van-button plain type="primary" class="one" @click="deleteTask()">
            <img src="@/assets/images/trash.svg" class="icon" />
            {{ $t('btn.delete') }}
        </van-button> -->

        <Edit
            :isCreate="isCreate"
            :task="task"
            @close="isCreate = false"
            @updateTask="updateTask" />
    </div>
</template>

<script>
// import ApiCategory from '@/api/category';
import ApiTasks from '@/api/tasks';
import Edit from '@/components/Task/Edit';
import { eventBus } from '@/main';

export default {
    props: {
        task: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isCreate: false,
        };
    },
    components: {
        Edit,
    },
    methods: {
        updateTask(data) {
            this.$emit('updateTask', data);
        },
        doneTask() {
            if (this.task.id_category == 0) {
                this.$notify(this.$t('task.cant_done'), {
                    type: 'danger',
                });
                return;
            }
            this.$dialog
                .confirm({
                    cancelButtonText: this.$t('category.no'),
                    confirmButtonText: this.$t('category.yes'),
                    closeOnClickOverlay: true,
                    message: this.$t('task.confirm_done'),
                })
                .then(() => {
                    ApiTasks.putTask({
                        id: this.task.id,
                        done: 1,
                    }).then(() => {
                        this.$notify(this.$t('task.done'), {
                            type: 'danger',
                        });
                        eventBus.$emit('doneTask');
                        // this.$router.push('/');
                    });
                });
        },
        deleteTask() {
            this.$dialog
                .confirm({
                    cancelButtonText: this.$t('category.no'),
                    confirmButtonText: this.$t('category.yes'),
                    closeOnClickOverlay: true,
                    message: this.$t('category.confirm_delete'),
                })
                .then(() => {
                    ApiTasks.deleteTask(this.task.id).then(() => {
                        this.$notify(this.$t('task.delete_task'), {
                            type: 'danger',
                        });
                        // this.$router.push('/');
                        eventBus.$emit('doneTask');
                    });
                });
        },
        getOriginalMessage() {
            const tg = window.Telegram.WebApp;

            ApiTasks.getTaskMessage(this.task.id).then(() => {
                tg.close();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-row {
    margin-bottom: 12px;
}
</style>
