<template>
    <van-dialog
        v-model="isCopyDelegate"
        :title="$t('btn.delegete')"
        :showConfirmButton="false"
        :closeOnClickOverlay="true"
        @close="close">
        <div class="delegete-wrap">
            <div v-if="loading" class="loading-holder">
                <van-loading />
            </div>
            <div
                v-else
                v-for="user in users"
                :key="user.id"
                class="delegete-item"
                @click="delegateTask(user.id)">
                <van-image round width="46" height="46" :src="user.avatar" />
                <p class="delegete-item__name">{{ user.first_name }} {{ user.last_name }}</p>
            </div>
        </div>
        <van-button
            v-if="showChooseButton"
            type="primary"
            class="one"
            @click="isCopyDelegate = false">
            {{ $t('btn.choose') }}
        </van-button>
    </van-dialog>
</template>

<script>
import ApiCategory from '@/api/category';
import ApiUsers from '@/api/users';
import ApiTasks from '@/api/tasks';

export default {
    props: {
        isDelegate: {
            default: false,
            type: Boolean,
        },
        showChooseButton: {
            default: true,
            type: Boolean,
        },
        task_id: {
            default: null,
            type: Number,
        },
    },
    data() {
        return {
            isError: false,
            isSaving: false,
            isCopyDelegate: false,
            form: {},
            users: [],
            loading: true,
        };
    },
    watch: {
        isDelegate() {
            this.isCopyDelegate = this.isDelegate;

            if (this.isDelegate == true) {
                ApiUsers.getUsers()
                    .then((responce) => {
                        this.loading = false;
                        this.users = responce.data;
                    })
                    .catch((error) => {
                        console.log(error, 'error');
                    });
            }
        },
    },
    methods: {
        save() {
            if (!this.form.title) {
                this.isError = true;
                return;
            }

            this.isError = false;
            this.isSaving = true;
            ApiCategory.createCategory(this.form).then((responce) => {
                this.isSaving = false;
                if (responce.err != 0) {
                    this.$notify(responce.err_msg[0], {
                        type: 'danger',
                    });
                } else {
                    this.resetForm();
                }
            });
        },
        close() {
            this.$emit('close');
        },
        delegateTask(userId) {
            ApiTasks.delegateTask({ for_user: userId, id: this.task_id }).then((response) => {
                console.log(response);
                this.isCopyDelegate = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.loading-holder {
    display: flex;
    justify-content: center;
}
</style>
