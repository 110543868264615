import API from '@/api';
export default {
  getTasksByCategory: (params) => {
    return API.get('/tasks', {
      params
    });
  },
  getTask: (id) => {
    return API.get(`/tasks/${id}`);
  },
  deleteTask: (id = 0) => {
    return API.delete(`/tasks/${id}`);
  },
  putTask: (params = {}) => {
    return API.put(`/tasks/${params.id}`, params);
  },
  delegateTask: (params = {}) => {
    return API.put(`/tasks/${params.id}`, params)
  },
  getTaskMessage(id) {
    return API.get(`/tasks/${id}/message`);
  }
};
